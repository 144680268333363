import { FCWithChildren } from '../../../types/FCWithChildren';
import { Option } from '../../Option';
import XIcon from '../icon/XIcon';
import Tooltip from '../Tooltip';

export enum WordTagStyle {
  FILL,
  STROKE,
}

export type WordTagProps = {
  tag: Option<string, string>;
  className?: string;
  removeTag?: (id: string) => void;
  disabled?: boolean;
  tooltip?: string;
  style?: WordTagStyle;
};

const WordTag: FCWithChildren<WordTagProps> = (props) => {
  const { tag, className, removeTag, disabled, tooltip, style = WordTagStyle.FILL } = props;

  return (
    <Tooltip text={tooltip}>
      {(tooltip) => (
        <div
          {...tooltip}
          className={`relative mb-1 mr-2 inline-flex flex-row items-center px-3 py-1 ${
            disabled
              ? style === WordTagStyle.FILL
                ? 'bg-primary-1 border-primary-1 border-2'
                : 'border-primary-1 border-2'
              : 'bg-primary-1 border-primary-1 border-2'
          } rounded-md ${className}`}
          data-cy={`word-tag-${tag.id}`}
        >
          <div
            className={`${removeTag && !disabled && 'pr-4'} text-dpm-14 ${
              disabled && style === WordTagStyle.STROKE ? 'text-primary-1' : 'text-white'
            } font-medium`}
          >
            {tag.text}
          </div>
          {removeTag && !disabled && (
            <XIcon
              className={`cursor-pointer ${disabled && style === WordTagStyle.STROKE ? 'text-primary-1' : 'text-white'} h-4 w-4`}
              onClick={() => removeTag && removeTag(tag.id)}
            />
          )}
          {props.children}
        </div>
      )}
    </Tooltip>
  );
};

export default WordTag;
